export const ADD_USER = "ADD_USER"
export const ADD_USERS = "AUTH_USERS"
export const ADD_CATEGORIES = "ADD_CATEGORIES"
export const ADD_FEEDBACK = "ADD_FEEDBACK"
export const ADD_FLAG = "ADD_FLAG"
export const ADD_ITEMS = "ADD_ITEMS"
export const ADD_STATES = "ADD_STATES"
export const ADD_CITIES = "ADD_CITIES"
export const ADD_ALL_CATEGORIES = "ADD_ALL_CATEGORIES"
export const ADD_USER_ITEMS = "ADD_USER_ITEMS"
